import { Amplify, Auth } from "aws-amplify";
import {
  DEMO_JWT,
  DEMO_SESSION,
  DEMO_USER,
  DEMO_USER_INFO
} from "./DemoConstants";
import { isSSO } from "../config";

const cognitoRegion = process.env.REACT_APP_COGNITO_REGION;
const identityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const webClientId = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;
const oauthDomain = `${process.env.REACT_APP_TENANT}-orbitalrx.auth.us-east-1.amazoncognito.com`;
const redirect = "https://" + `${process.env.REACT_APP_TENANT}.orbitalrx.com`;

export const configureAuth = () => {
  if (inDemoMode()) {
    return;
  }

  if (!(cognitoRegion && identityPoolId && userPoolId && webClientId)) {
    throw "cognito config missing";
  }

  if (isSSO) {
    Amplify.configure({
      storage: sessionStorage,
      identityPoolId: identityPoolId,
      region: cognitoRegion,
      userPoolId: userPoolId,
      userPoolWebClientId: webClientId,
      // deepcode ignore BadOperandForBitwiseOperation: this is the syntax for using two auth flow types in amplify config
      authenticationFlowType: "USER_SRP_AUTH" | "USER_PASSWORD_AUTH",
      oauth: {
        domain: oauthDomain,
        scope: ["email", "openid"],
        redirectSignIn: redirect,
        redirectSignOut: redirect
      }
    });
  } else {
    Amplify.configure({
      storage: sessionStorage,
      identityPoolId: identityPoolId,
      region: cognitoRegion,
      userPoolId: userPoolId,
      userPoolWebClientId: webClientId,
      authenticationFlowType: "USER_PASSWORD_AUTH"
    });
  }
};

export const inDemoMode = () => "REACT_APP_DEMO_MODE" in process.env;

export const notAuthScreen = path => {
  return !path.includes("/auth");
};

export const getJWT = () => {
  return inDemoMode()
    ? Promise.resolve(DEMO_JWT)
    : Auth.currentSession().then(session => session.idToken.jwtToken);
};

export const currentAuthenticatedUser = async () => {
  return inDemoMode()
    ? Promise.resolve(DEMO_USER)
    : Auth.currentAuthenticatedUser().catch(e => {
        if (e.includes("not authenticated") && notAuthScreen(location.href)) {
          console.log(e, "-- signing out");
          console.log(location);
          signOut();
          return;
        } else if (notAuthScreen(location.href)) {
          return;
        }
        throw e;
      });
};

export const currentSession = () => {
  return inDemoMode() ? Promise.resolve(DEMO_SESSION) : Auth.currentSession();
};

export const signOut = async () => {
  if (!inDemoMode()) {
    try {
      await Auth.signOut({
        global: true
      });
    } catch (err) {
      console.log("error signing out");
    }
    window.location.replace("/#/auth/login");
  } else {
    window.location.replace("/#/auth/login");
  }
};

export const currentUserInfo = () => {
  return inDemoMode()
    ? Promise.resolve(DEMO_USER_INFO)
    : Auth.currentUserInfo();
};

// Authorized Page constants
export const ADMIN_PAGE = "ADMIN";
export const ADMIN_USER_PAGE = "ADMIN_USERS";
export const ADMIN_MEDID_ASSIGNMENT_PAGE = "ADMIN_MEDID_ASSIGNMENT";
export const ADMIN_DISPGROUP_ASSIGNMENT_PAGE = "ADMIN_DISPGROUP_ASSIGNMENT";
export const ADMIN_LABEL_MGMT_PAGE = "ADMIN_LABEL_MGMT";
export const AMIN_EXT_COMM_MGMT_PAGE = "ADMIN_EXT_COMM_MGMT";

export const canRenderAdminPages = authorizedPages =>
  authorizedPages.some(page => page == ADMIN_PAGE);

export const canRenderAdminUserPage = authorizedPages =>
  authorizedPages.some(page => page == ADMIN_USER_PAGE);

export const canRenderAdminMedIdAssignmentPage = authorizedPages =>
  authorizedPages.some(page => page == ADMIN_MEDID_ASSIGNMENT_PAGE);

export const canRenderAdminDispGroupAssignmentPage = authorizedPages =>
  authorizedPages.some(page => page == ADMIN_DISPGROUP_ASSIGNMENT_PAGE);

export const canRenderAdminLabelMgmtPage = authorizedPages =>
  authorizedPages.some(page => page == ADMIN_LABEL_MGMT_PAGE);

export const canRenderAdminExtCommMgmtPage = authorizedPages =>
  authorizedPages.some(page => page == AMIN_EXT_COMM_MGMT_PAGE);
